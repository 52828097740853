import React from "react"
import KohlerLogo from "../images/kohler-logo.svg"
import DeltaLogo from "../images/delta-logo.svg"
import RheemLogo from "../images/rheem-logo.svg"
import InsinkeratorLogo from "../images/insinkerator-logo.svg"

const SubServices = props => {
  return (
    <section id="partners">
      <div className="fullWidth py-16 px-2 md:px-0">
        <h2 className="text-3xl text-center font-semibold text-blue-400 pb-16">
          {props.mainTitle || `Our Partners`}
        </h2>
        <div className="container">
          <div className="w-full md:mb-8 lg:mr-8">
            <div className="md:flex">
              <div className="md:w-1/2 lg:max-w-1/4 mb-8 md:mb-0">
                <div className="flex justify-around">
                  <KohlerLogo
                    alt="Kohler Logo"
                    className="align-center h-32 w-32 mr-4 md:mr-0"
                  />
                  <DeltaLogo
                    alt="Delta Logo"
                    className="align-center h-32 w-32"
                  />
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 mb-8 md:mb-0">
                <div className="flex items-center justify-around">
                  <RheemLogo
                    alt="Rheem Logo"
                    className="align-center h-24 w-24 mr-8 md:mr-0"
                  />
                  <InsinkeratorLogo
                    alt="Insinkerator Logo"
                    className="align-center h-32 w-32"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubServices
