import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Button from "./mainButton"

const Services = () => {
  const data = useStaticQuery(graphql`
    query Images {
      plumbing: file(relativePath: { eq: "plumbing-repair-services.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gasLines: file(relativePath: { eq: "gas-line-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      waterHeaters: file(relativePath: { eq: "water-heater-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sewers: file(relativePath: { eq: "sewer-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section id="services">
      <div className="py-16 px-2 md:px-0">
        <h2 className="text-3xl text-center font-semibold text-blue-400 pb-16">
          Our Services
        </h2>
        <div className="lg:flex">
          <div className="md:w-full lg:w-1/2 md:mb-8 lg:mb-0  lg:mr-8">
            <div className="md:flex">
              <div className="md:w-1/2 lg:max-w-1/4 rounded overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                <Img
                  className="w-full"
                  fluid={data.plumbing.childImageSharp.fluid}
                  alt="Plumbing Services"
                />
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">Plumbing</div>
                  <p className="text-gray-600 text-base mb-4">
                    We work on sinks, showers, toilets, and more. Whether you
                    need a repair or a new installation, we can have it done
                    quickly.
                  </p>
                  <Button link={"/plumbing"} />
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 rounded overflow-hidden shadow-lg mb-8 md:mb-0">
                <Img
                  className="w-full"
                  fluid={data.waterHeaters.childImageSharp.fluid}
                  alt="Water Heater Services"
                />
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">Water Heaters</div>
                  <p className="text-gray-600 text-base mb-4">
                    No hot water? We perform repairs and general maintenance on
                    your home's water heater so you can enjoy hot water ASAP.
                  </p>
                  <Button link={"/water-heater-repair"} />
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-full lg:w-1/2">
            <div className="md:flex">
              <div className="md:w-1/2 lg:max-w-1/4 rounded overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                <Img
                  className="w-full"
                  fluid={data.gasLines.childImageSharp.fluid}
                  alt="Gas Line Services"
                />
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">Gas Lines</div>
                  <p className="text-gray-600 text-base mb-4">
                    Whether you your gas lines are leaking or just need a
                    routine inspection, our experienced team is able to keep
                    your home safe.
                  </p>
                  <Button link={"/gas-line-repair"} />
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 rounded overflow-hidden shadow-lg mb-8 md:mb-0">
                <Img
                  className="w-full"
                  fluid={data.sewers.childImageSharp.fluid}
                  alt="Sewer Services"
                />
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <div className="font-bold text-xl mb-4">Sewers</div>
                  <p className="text-gray-600 text-base mb-4">
                    Our highly-trained team allows you to have peace of mind
                    about your sewer or water line repair. We get the job done
                    right the first time.
                  </p>
                  <Button link={"/sewers"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
