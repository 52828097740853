import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import CTA from "../components/cta"
import USP from "../components/usp"
import Services from "../components/services"
import SEO from "../components/seo"
import Testimonials from "../components/testimonials"
import Partners from "../components/partners"
import BackgroundImage from "gatsby-background-image"
import Button from "../components/callButton"

const IndexPage = () => {
  const { desktopImage } = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "kitchen-sink-1.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 100, maxWidth: 4160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const sources = [
    {
      ...desktopImage.childImageSharp.fluid,
    },
  ]
  return (
    <Layout>
      <SEO
        title="North Houston Residential Plumbing Company"
        description={`Klein Plumbing Services offers superior, affordable residential plumbing solutions for North Houston communities. We're certified, reliable, and fast.`}
      />
      <BackgroundImage className="fullWidth bg-cover" fluid={sources}>
        <section className="flex items-center container h-screen-hero">
          <div className="px-2">
            <h1 className="text-sm text-gray-200 mb-4 md:text-base lg:text-lg xl:text-xl xl:mb-8">
              Your Local Master Plumber
            </h1>
            <h2 className="text-xl text-white font-semibold leading-relaxed md:text-3xl lg:text-4xl lg:leading-snug lg:mb-4 xl:text-5xl">
              Family Owned Residential <br /> Plumbing Since 2006
            </h2>
            <Button />
            <p className="mt-4 text-gray-200 text-sm md:text-base lg:text-lg xl:text-xl xl:mt-10">
              100% Client Satisfaction Guarantee
            </p>
          </div>
        </section>
      </BackgroundImage>
      <Services />
      <USP />
      {/* <Testimonials /> */}
      <Partners />
      <CTA />
    </Layout>
  )
}

export default IndexPage
